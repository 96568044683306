
import { mapState } from 'vuex'
export default {
  name: 'PrimaryFooter',
  computed: {
    ...mapState({
      sideLinks: (state) => state.links,
      company: (state) => state.company,
    }),
  },
  methods: {
    isValidHttpUrl(string) {
      let url

      try {
        url = new URL(string)
      } catch (_) {
        return false
      }

      return url.protocol === 'http:' || url.protocol === 'https:'
    },
  },
}
